const dev = {
    apiPath: "http://localhost:5000"
}

const test = {
    apiPath: "https://test.webapp.e-nundation.com"
}

const prod = {
    apiPath: "https://webapp.e-nundation.com/b"
}


const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : (process.env.REACT_APP_STAGE === 'test' ? test : dev);


const clientId = "vS62bq4QgOjmsXeC"
const portalUrl = "https://geosapiens-agol.maps.arcgis.com"
const responseType = "code"
let LOGIN_SUCCESS_PAGE = "/secret";
let LOGIN_FAILURE_PAGE = "/";

export default {
  // Add common config values here
  clientId,
  portalUrl,
  responseType,
  LOGIN_FAILURE_PAGE,
  LOGIN_SUCCESS_PAGE,
  ...config
};