import React from "react";
import {connect} from "react-redux"
import * as actionCreators from "../store/actions";
import Header from "../components/Header";
import BottomPanel from "../components/BottomPanel";
import {WaterLevelMap} from "../components/WaterLevelMap";
import {AS} from "../constants/appsConstants";
import {Mentions} from "../components/Mentions";
import {Legende} from "../components/Legende";
import Validation from "../components/Validation";


// export default class AppAssomption extends React.Component {
export class AppAssomption extends React.Component {
  // state = { exportDataSet: [] };
  // callbackFunction = (childData) => this.setState({ exportDataSet: childData });

  render() {
    this.props.onSetApp("AS");
    return (
      <div className="app">
        <Header
          scenarioDepart={AS.scenarioDepart}
          nbScenario={AS.nbScenario}
          labels={AS.labels}
          btn2019={AS.btn2019}
          // btnHier={AS.btnHier}
          // btnTempsReel={AS.btnTempsReel}
          // btnUnJour={AS.btnUnJour}
          // btnTroisJours={AS.btnTroisJours}
          yearMarkers={AS.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={AS.scenarioDepart}
          labels={AS.labels}
          dataKm={AS.dataKm}
          dataSacs={AS.dataSacs}
          dataIsole={AS.dataIsole}
          // dataSS={AS.dataSS}
          // dataPrem={AS.dataPrem}
          dataTot={AS.dataTot}
          dataEvac={AS.dataEvac}
          // exportDataSet={this.state.exportDataSet}
        />

        <WaterLevelMap
          configuration={AS}
          // exportDataSetCallback={this.callbackFunction}
          labels={AS.labels}
          store={this.props.store}
        />

        <Mentions/>
        <Legende/>
        <Validation/>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetApp: (id) => dispatch(actionCreators.setApp(id)),
  }
};

export default connect(null, mapDispatchToProps)(AppAssomption)