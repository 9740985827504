// Assomption
export const AS = {
  configName: 'assomption',
  scenarioDepart: 16,
  nbScenario: 81,
  startScenario: 1,
  stepScenario: 1,
  // labels: ["100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295", "300", "305", "310", "315", "320", "325", "330", "335", "340", "345", "350", "355", "360", "365", "370", "375", "380", "385", "390", "395", "400", "405", "410", "415", "420", "425", "430", "435", "440", "445", "450", "455", "460", "465", "470", "475", "480", "485", "490", "495", "500"],
  //version -40m3s
  labels: ["60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295", "300", "305", "310", "315", "320", "325", "330", "335", "340", "345", "350", "355", "360", "365", "370", "375", "380", "385", "390", "395", "400", "405", "410", "415", "420", "425", "430", "435", "440", "445", "450", "455", "460"],
  dataKm: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.2, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.6, 0.7, 0.8, 1.0, 1.1, 1.3, 1.5, 1.7, 1.8, 2.0, 2.1, 2.3, 2.8, 3.2, 3.5, 3.6, 4.0, 4.3, 4.5, 4.8, 5.1, 6.1, 6.6, 7.0, 7.3, 7.7, 8.1, 8.4, 8.6, 8.9, 9.2, 9.4, 9.6, 10.0, 10.3, 10.5, 10.7, 10.9, 11.2, 11.4],
  dataSacs: [0, 0, 0, 0, 0, 0, 27, 32, 39, 45, 52, 58, 62, 71, 80, 86, 93, 103, 113, 176, 208, 228, 282, 333, 454, 525, 598, 706, 816, 918, 1076, 1233, 1431, 1695, 1927, 2176, 2393, 2683, 3161, 3560, 4097, 4761, 5384, 6126, 6921, 7819, 8806, 9869, 11266, 12777, 14837, 16843, 19521, 22789, 26071, 29545, 33508, 37724, 42456, 47363, 52936, 59105, 67800, 76637, 86556, 96237, 107533, 120453, 133907, 148676, 164813, 182908, 200415, 218274, 237020, 257599, 278939, 299042, 321092, 342735, 365628],
  dataIsole: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 6, 6, 6, 6, 6, 6, 7, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 13, 16, 15, 16, 16, 30, 28, 28, 34, 39, 34, 34, 79, 88, 105, 103, 105, 106, 101, 99, 103, 112, 107, 106, 103, 85, 87, 82, 84, 72, 73, 70, 63, 61, 55, 49, 44],
  // dataSS: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 4, 4, 4, 4, 5, 7, 11, 12, 12, 11, 15, 16, 18, 20, 23, 25, 28, 40, 49, 59, 67, 80, 90, 99, 104, 113, 121, 122, 128, 138, 200, 214, 230, 244, 255, 266, 281, 302, 304, 308, 306, 306, 307, 300, 281, 265, 254, 240, 222],
  // dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 4, 4, 4, 5, 5, 5, 6, 6, 7, 7, 7, 8, 10, 13, 14, 16, 20, 24, 25, 29, 40, 43, 47, 57, 68, 70, 76, 90, 103, 116, 132, 144, 164, 195, 223, 249, 269, 294],
  dataTot: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 4, 4, 4, 4, 5, 7, 11, 12, 13, 13, 17, 20, 22, 24, 28, 30, 33, 46, 55, 66, 74, 87, 98, 109, 117, 127, 137, 142, 152, 163, 229, 254, 273, 291, 312, 334, 351, 378, 394, 411, 422, 438, 451, 464, 476, 488, 503, 509, 516],
  dataEvac: [13, 13, 13, 13, 13, 13, 16, 16, 16, 16, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 14, 14, 23, 24, 24, 24, 19, 19, 25, 20, 20, 20, 21, 28, 45, 48, 53, 55, 72, 85, 109, 123, 135, 142, 155, 243, 263, 297, 338, 392, 408, 446, 596, 652, 720, 736, 775, 814, 1008, 1074, 1142, 1226, 1275, 1322, 1369, 1399, 1452, 1484, 1526, 1539, 1583, 1614, 1636, 1667, 1690, 1691, 1698],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_SacsSable/FeatureServer/",
  stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_StationHydro/FeatureServer/",
  serviceEssentiel_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_services_essentiels/FeatureServer/",
  photoUrls: ["https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Photos_drone_mosaic/MapServer"],
  centerView: [-73.444359, 46.041463],
  centerViewZoom: 15,
  btn2019: 43, //275 m3s
  // btnHier: 11,
  // btnTempsReel: 16,
  // btnUnJour: 23,
  // btnTroisJours: 38,
  yearMarkers: [
    {
      value:24, //180
      label: '2',
    },
    {
      value: 54, //330
      label: '20',
    },
    {
      value: 67, //395
      label: '100',
    }
  ],
  // isVisible: false
};
// Assomption Niveau
export const ASN = {
  configName: 'assomptionNiveau',
  scenarioDepart: 16,
  nbScenario: 81,
  startScenario: 1,
  stepScenario: 1,
  labels: ["56.61", "56.69", "56.76", "56.84", "56.91", "56.98", "57.04", "57.11", "57.17", "57.23", "57.29", "57.35", "57.41", "57.47", "57.52", "57.58", "57.63", "57.68", "57.74", "57.79", "57.84", "57.89", "57.94", "57.99", "58.04", "58.09", "58.15", "58.2", "58.25", "58.3", "58.35", "58.41", "58.46", "58.52", "58.58", "58.63", "58.69", "58.75", "58.82", "58.88", "58.95", "59.01", "59.08", "59.16", "59.23", "59.31", "59.38", "59.47", "59.55", "59.64", "59.72", "59.82", "59.91", "60.01", "60.11", "60.22", "60.33", "60.44", "60.55", "60.67", "60.8", "60.92", "61.05", "61.19", "61.33", "61.47", "61.62", "61.78", "61.94", "62.1", "62.27", "62.44", "62.62", "62.8", "62.99", "63.19", "63.39", "63.6", "63.81", "64.03", "64.25"],
  dataKm: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.2, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.6, 0.7, 0.8, 1.0, 1.1, 1.3, 1.5, 1.7, 1.8, 2.0, 2.1, 2.3, 2.8, 3.2, 3.5, 3.6, 4.0, 4.3, 4.5, 4.8, 5.1, 6.1, 6.6, 7.0, 7.3, 7.7, 8.1, 8.4, 8.6, 8.9, 9.2, 9.4, 9.6, 10.0, 10.3, 10.5, 10.7, 10.9, 11.2, 11.4],
  dataSacs: [0, 0, 0, 0, 0, 0, 27, 32, 39, 45, 52, 58, 62, 71, 80, 86, 93, 103, 113, 176, 208, 228, 282, 333, 454, 525, 598, 706, 816, 918, 1076, 1233, 1431, 1695, 1927, 2176, 2393, 2683, 3161, 3560, 4097, 4761, 5384, 6126, 6921, 7819, 8806, 9869, 11266, 12777, 14837, 16843, 19521, 22789, 26071, 29545, 33508, 37724, 42456, 47363, 52936, 59105, 67800, 76637, 86556, 96237, 107533, 120453, 133907, 148676, 164813, 182908, 200415, 218274, 237020, 257599, 278939, 299042, 321092, 342735, 365628],
  dataIsole: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 6, 6, 6, 6, 6, 6, 7, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 13, 16, 15, 16, 16, 30, 28, 28, 34, 39, 34, 34, 79, 88, 105, 103, 105, 106, 101, 99, 103, 112, 107, 106, 103, 85, 87, 82, 84, 72, 73, 70, 63, 61, 55, 49, 44],
  dataTot: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 4, 4, 4, 4, 5, 7, 11, 12, 13, 13, 17, 20, 22, 24, 28, 30, 33, 46, 55, 66, 74, 87, 98, 109, 117, 127, 137, 142, 152, 163, 229, 254, 273, 291, 312, 334, 351, 378, 394, 411, 422, 438, 451, 464, 476, 488, 503, 509, 516],
  dataEvac: [13, 13, 13, 13, 13, 13, 16, 16, 16, 16, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 14, 14, 23, 24, 24, 24, 19, 19, 25, 20, 20, 20, 21, 28, 45, 48, 53, 55, 72, 85, 109, 123, 135, 142, 155, 243, 263, 297, 338, 392, 408, 446, 596, 652, 720, 736, 775, 814, 1008, 1074, 1142, 1226, 1275, 1322, 1369, 1399, 1452, 1484, 1526, 1539, 1583, 1614, 1636, 1667, 1690, 1691, 1698],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_SacsSable/FeatureServer/",
  stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_StationHydro/FeatureServer/",
  serviceEssentiel_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_services_essentiels/FeatureServer/",
  photoUrls: [],
  centerView: [-73.444359, 46.041463],
  centerViewZoom: 15,
  btn2019: 43, //275 m3s
  // btnHier: 11,
  // btnTempsReel: 16,
  // btnUnJour: 23,
  // btnTroisJours: 38,
  yearMarkers: [
    {
      value:24, //180
      label: '2',
    },
    {
      value: 54, //330
      label: '20',
    },
    {
      value: 67, //395
      label: '100',
    }
  ],
  // isVisible: false
};
// Assomption NDPJ
export const AS_NDPJ = {
  configName: 'assomptionNDPJ',
  scenarioDepart: 16,
  nbScenario: 81,
  startScenario: 1,
  stepScenario: 1,
  labels: ["60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295", "300", "305", "310", "315", "320", "325", "330", "335", "340", "345", "350", "355", "360", "365", "370", "375", "380", "385", "390", "395", "400", "405", "410", "415", "420", "425", "430", "435", "440", "445", "450", "455", "460"],
  dataKm: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.2, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.6, 0.7, 0.7, 0.8, 0.8, 0.8, 0.9, 0.9, 1.0, 1.1, 1.2, 1.5, 1.7, 1.7, 1.7, 1.8, 2.0, 1.7, 2.2, 2.3, 3.2, 3.7, 3.8, 4.2, 4.5, 4.9, 5.1, 5.0, 5.2, 5.2, 5.4, 5.9, 6.0, 6.3, 6.4, 6.6, 6.3, 6.9, 6.9],
  dataSacs: [0, 0, 0, 0, 0, 0, 27, 32, 39, 45, 52, 58, 62, 71, 80, 86, 93, 103, 113, 121, 128, 137, 150, 162, 236, 286, 322, 389, 457, 524, 612, 708, 816, 928, 1039, 1167, 1284, 1447, 1677, 1940, 2267, 2658, 3052, 3526, 3991, 4515, 5120, 5720, 6590, 7385, 8360, 9288, 10552, 12483, 14361, 15821, 17966, 20177, 22436, 25194, 28423, 31666, 37817, 43693, 49927, 55968, 63294, 71650, 80222, 89889, 100365, 111525, 123139, 134522, 146628, 160472, 173589, 186563, 201278, 215380, 230764],
  dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 7, 10, 10, 8, 7, 7, 7, 6, 7, 14, 11, 10, 53, 52, 49, 48, 47, 45, 47, 51, 61, 65, 67, 69, 59, 54, 53, 56, 53, 49, 45, 40, 39, 34, 31, 26, 21],
  dataTot: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 8, 10, 11, 13, 14, 14, 14, 15, 17, 18, 20, 27, 29, 31, 33, 39, 53, 61, 65, 68, 76, 78, 83, 89, 94, 157, 176, 188, 192, 204, 220, 228, 239, 247, 254, 266, 272, 280, 286, 293, 301, 305, 310, 318],
  dataEvac: [0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 8, 10, 15, 20, 20, 20, 20, 20, 23, 20, 20, 20, 23, 32, 39, 40, 44, 50, 50, 50, 66, 79, 82, 82, 99, 105, 113, 116, 140, 202, 213, 223, 372, 387, 383, 398, 395, 406, 601, 673, 742, 772, 814, 861, 856, 875, 899, 935, 968, 974, 982, 986, 1007, 1015, 1020, 1020, 1033],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionNDPJ_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionNDPJ_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionNDPJ_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionNDPJ_SacsSable/FeatureServer/",
  stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_StationHydro/FeatureServer/",
  serviceEssentiel_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_services_essentiels/FeatureServer/",
  photoUrls: [],
  centerView: [-73.444359, 46.041463],
  centerViewZoom: 15,
  btn2019: 43, //275 m3s
  // btnHier: 11,
  // btnTempsReel: 16,
  // btnUnJour: 23,
  // btnTroisJours: 38,
  yearMarkers: [
    {
      value:24, //180
      label: '2',
    },
    {
      value: 54, //330
      label: '20',
    },
    {
      value: 67, //395
      label: '100',
    }
  ]
};
// Assomption SCB
export const AS_SCB = {
  configName: 'assomptionSCB',
  scenarioDepart: 16,
  nbScenario: 81,
  startScenario: 1,
  stepScenario: 1,
  labels: ["60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295", "300", "305", "310", "315", "320", "325", "330", "335", "340", "345", "350", "355", "360", "365", "370", "375", "380", "385", "390", "395", "400", "405", "410", "415", "420", "425", "430", "435", "440", "445", "450", "455", "460"],
  dataKm: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.3, 0.5, 0.7, 0.8, 0.9, 0.9, 1.0, 1.2, 1.3, 1.5, 1.7, 1.8, 2.1, 2.2, 2.4, 2.5, 2.7, 2.8, 2.9, 2.9, 3.0, 3.0, 3.1, 3.1, 3.2, 3.2, 3.3, 3.3, 3.4, 3.5, 3.5, 3.6, 3.7, 3.7, 3.8, 3.8],
  dataSacs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 55, 80, 91, 132, 171, 218, 239, 276, 317, 359, 394, 458, 513, 583, 668, 735, 817, 872, 951, 1150, 1213, 1346, 1532, 1634, 1755, 1892, 2021, 2182, 2449, 2741, 3222, 4077, 4909, 6070, 7085, 8194, 9896, 11389, 12987, 14987, 16728, 18578, 21011, 23094, 25582, 28744, 31814, 35184, 39143, 43374, 47881, 52853, 58971, 64137, 69881, 75760, 81686, 89106, 95413, 101924, 108614, 115261],
  dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 4, 4, 4, 4, 4, 4, 4, 21, 13, 16, 22, 20, 20, 20, 23, 31, 55, 54, 58, 60, 54, 47, 41, 45, 40, 32, 33, 26, 22, 19, 17, 16, 16, 17, 18, 17, 15, 12, 11],
  dataTot: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 4, 4, 5, 7, 7, 7, 7, 7, 8, 13, 16, 23, 35, 40, 42, 45, 48, 57, 59, 62, 65, 67, 68, 71, 74, 85, 95, 103, 112, 124, 138, 147, 153, 162, 167, 170, 172, 174, 178, 180, 186, 188, 190],
  dataEvac: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 7, 7, 7, 7, 7, 7, 7, 7, 7, 11, 11, 22, 28, 28, 28, 28, 28, 33, 49, 58, 122, 132, 149, 170, 173, 186, 212, 228, 267, 354, 358, 362, 374, 363, 375, 385, 422, 433, 430, 485, 490, 499, 516, 525, 535, 542, 551, 569, 570, 581, 581, 586],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionSCB_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionSCB_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionSCB_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionSCB_SacsSable/FeatureServer/",
  stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_StationHydro/FeatureServer/",
  serviceEssentiel_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_services_essentiels/FeatureServer/",
  photoUrls: [],
  centerView: [-73.444359, 46.041463],
  centerViewZoom: 15,
  btn2019: 43, //275 m3s
  // btnHier: 11,
  // btnTempsReel: 16,
  // btnUnJour: 23,
  // btnTroisJours: 38,
  yearMarkers: [
    {
      value:24, //180
      label: '2',
    },
    {
      value: 54, //330
      label: '20',
    },
    {
      value: 67, //395
      label: '100',
    }
  ]
};
// Deux Montagnes
export const DM = {
  configName: 'deuxMontagnes',
  scenarioDepart: 12,
  nbScenario: 69,
  startScenario: 0,
  stepScenario: 1,
  // DeuxMontagnes complet
  // labels: ["22.70", "22.75", "22.80", "22.85", "22.90", "22.95", "23.00", "23.05", "23.10", "23.15", "23.20", "23.25", "23.30", "23.35", "23.40", "23.45", "23.50", "23.55", "23.60", "23.65", "23.70", "23.75", "23.80", "23.85", "23.90", "23.95", "24.00", "24.05", "24.10", "24.15", "24.20", "24.25", "24.30", "24.35", "24.40", "24.45", "24.50", "24.55", "24.60", "24.65", "24.70", "24.75", "24.80", "24.85", "24.90", "24.95", "25.00", "25.05", "25.10", "25.15", "25.20", "25.25", "25.30", "25.35", "25.40", "25.45", "25.50", "25.55", "25.60", "25.65", "25.70", "25.75", "25.80", "25.85", "25.90", "25.95", "26.00", "24.70", "24.70"],
  // dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 4, 4, 3, 4, 4, 13, 8, 21, 19, 36, 43, 50, 54, 56, 67, 68, 80, 108, 102, 121, 127, 171, 160, 153, 181, 270, 254, 271, 328, 348, 375, 382, 383, 568, 677, 678, 699, 691, 721, 766, 771, 801, 911, 1106, 1036, 1070, 1029, 1215, 1304, 1299, 1405, 181, 175],
  // dataSS: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 3, 5, 5, 6, 6, 7, 13, 12, 15, 19, 28, 31, 36, 42, 62, 69, 88, 103, 114, 142, 174, 292, 322, 348, 382, 411, 457, 483, 510, 513, 553, 539, 590, 608, 678, 697, 781, 787, 867, 874, 957, 987, 1061, 1097, 1218, 1231, 1341, 1290, 1386, 1359, 1457, 1430, 1544, 1512, 1662, 1656, 513, 483],
  // dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 2, 4, 4, 5, 5, 7, 7, 9, 10, 15, 16, 24, 26, 34, 36, 51, 55, 85, 103, 154, 173, 233, 237, 282, 288, 350, 352, 419, 423, 525, 529, 616, 622, 727, 736, 863, 872, 1004, 1014, 1173, 1183, 1344, 1353, 1539, 1546, 1725, 154, 154],
  // dataTot: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 3, 5, 5, 6, 6, 7, 13, 13, 16, 21, 30, 35, 40, 47, 67, 76, 95, 112, 124, 157, 190, 316, 348, 382, 418, 462, 512, 568, 613, 667, 726, 772, 827, 890, 966, 1047, 1133, 1206, 1290, 1399, 1486, 1603, 1683, 1824, 1954, 2094, 2213, 2294, 2400, 2532, 2640, 2774, 2897, 3051, 3208, 3381, 667, 637],
  // dataEvac: [0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 8, 16, 16, 19, 22, 33, 58, 52, 69, 89, 150, 146, 203, 219, 327, 377, 456, 516, 559, 672, 780, 1229, 1413, 1483, 1650, 1805, 2077, 2210, 2326, 2580, 3055, 3138, 3348, 3719, 4017, 4351, 4651, 4890, 5683, 6372, 6635, 7050, 7241, 7747, 8136, 8601, 9021, 9576, 10473, 10663, 11066, 11337, 12287, 12998, 13484, 14302, 2580, 2440],
  // Secteur Vaudreuil
  labels: ["22.70", "22.75", "22.80", "22.85", "22.90", "22.95", "23.00", "23.05", "23.10", "23.15", "23.20", "23.25", "23.30", "23.35", "23.40", "23.45", "23.50", "23.55", "23.60", "23.65", "23.70", "23.75", "23.80", "23.85", "23.90", "23.95", "24.00", "24.05", "24.10", "24.15", "24.20", "24.25", "24.30", "24.35", "24.40", "24.45", "24.50", "24.55", "24.60", "24.65", "24.70", "24.70 digue", "24.75", "24.80", "24.85", "24.90", "24.95", "25.00", "25.05", "25.10", "25.15", "25.20", "25.25", "25.30", "25.35", "25.40", "25.45", "25.50", "25.55", "25.60", "25.65", "25.70", "25.75", "25.80", "25.85", "25.90", "25.95", "26.00", "24.70"],
  dataKm: [],
  dataSacs: [],
  dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 4, 4, 7, 4, 10, 6, 10, 8, 10, 7, 8, 8, 10, 11, 34, 36, 39, 48, 42, 36, 33, 37, 37, 54, 69, 95, 97, 112, 130, 117, 127, 125, 240, 250, 233, 242, 232, 281, 268, 292, 290, 321, 276, 331, 301, 449, 562, 573, 544],
  // dataSS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 5, 6, 8, 8, 11, 15, 18, 19, 24, 32, 47, 55, 62, 70, 80, 91, 112, 123, 136, 153, 153, 171, 180, 203, 222, 265, 292, 339, 352, 396, 422, 476, 520, 565, 603, 692, 733, 811, 781, 839, 823, 881, 859, 939, 917, 1030, 1034],
  // dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 2, 5, 5, 11, 12, 19, 19, 21, 29, 29, 40, 42, 61, 62, 86, 88, 130, 132, 165, 167, 222, 226, 287, 290, 376, 381, 481, 488, 603, 608, 736, 740, 862],
  dataTot: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 5, 6, 8, 8, 11, 15, 18, 20, 25, 33, 48, 56, 63, 71, 82, 96, 117, 134, 148, 172, 172, 192, 209, 232, 262, 307, 353, 401, 438, 484, 552, 608, 685, 732, 825, 918, 1020, 1101, 1157, 1220, 1304, 1369, 1462, 1547, 1653, 1770, 1896],
  dataEvac: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 4, 3, 16, 20, 25, 25, 41, 31, 56, 44, 67, 70, 87, 86, 107, 129, 189, 217, 299, 331, 378, 450, 495, 525, 560, 642, 642, 768, 868, 1013, 1103, 1288, 1481, 1595, 1747, 1870, 2450, 2653, 2831, 2979, 3208, 3596, 3883, 4183, 4340, 4640, 4762, 5099, 5294, 6007, 6644, 7048, 7338],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_Plaine_Inondab",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_Batiments_V2/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_Batiments_Points/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DeuxMontagnes_freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vaudreuil_SacsSable/FeatureServer/",
  stationHydro_Url: "/",
  serviceEssentiel_Url: "/",
  photoUrls: ["https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/photo_inondation/MapServer"],
  // centerView: [-73.995097, 45.450559], // DeuxMontagnes complet
  // centerViewZoom: 13, // DeuxMontagnes complet
  centerView: [-73.990657, 45.391444], // Secteur Vaudreuil
  // centerView: [-73.990909, 45.390755], // Pour test
  centerViewZoom: 15, // Secteur Vaudreuil
  // centerViewZoom: 17, // Pour test
  btn2019: 40, //24.70m
  // btnHier: 1,
  // btnTempsReel: 2,
  // btnUnJour: 18,
  // btnTroisJours: 34,
  yearMarkers: [
    {
      value: 13, // This is the value position in the labels props, equals to 23.35
      label: '2',
    },
    {
      value: 28,
      label: '20',
    },
    {
      value: 35,
      label: '100',
    },
  ],
  // isVisible: false
};

// Mille Iles v1
// export const MI = {
//   configName: 'milleIles',
//   scenarioDepart: 8,
//   nbScenario: 37,
//   startScenario: 1,
//   stepScenario: 1,
//   labels: ["700", "725", "750", "775", "800", "825", "850", "875", "900", "925", "950", "975", "1000", "1025", "1050", "1075", "1100", "1125", "1150", "1175", "1200", "1225", "1250", "1275", "1300", "1325", "1350", "1375", "1400", "1425", "1450", "1475", "1500", "1525", "1550", "1575", "1600"],
//   dataIsole: [4, 4, 4, 4, 7, 19, 21, 33, 37, 39, 36, 31, 32, 41, 39, 36, 36, 38, 44, 31, 22, 33, 32, 32, 43, 39, 41, 45, 39, 38, 34, 28, 33, 23, 22, 35, 52],
//   dataSS: [0, 0, 0, 0, 3, 7, 10, 15, 17, 23, 28, 34, 40, 46, 54, 64, 83, 99, 114, 133, 146, 163, 180, 200, 223, 227, 243, 254, 260, 257, 249, 276, 276, 281, 287, 296, 302],
//   dataPrem: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 6, 10, 14, 15, 16, 27, 33, 40, 47, 54, 71, 86, 95, 116, 138, 164, 186, 213, 238, 261, 288, 314],
//   dataTot: [0, 0, 0, 0, 3, 7, 10, 15, 17, 23, 28, 34, 41, 49, 58, 70, 93, 113, 129, 149, 173, 196, 220, 247, 277, 298, 329, 349, 376, 395, 413, 462, 489, 519, 548, 584, 616],
//   dataEvac: [15, 15, 15, 15, 29, 78, 92, 140, 166, 192, 201, 203, 236, 282, 307, 325, 399, 458, 529, 536, 579, 676, 746, 824, 947, 1000, 1098, 1157, 1227, 1286, 1312, 1447, 1545, 1610, 1694, 1840, 1997],
//   waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_secteur_Plaine_Ino",
//   buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Batiments/FeatureServer/",
//   buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Batiments_pts/FeatureServer/",
//   buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Batiments_gc_secteur_freestyle/FeatureServer/",
//   streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Routes/FeatureServer/",
//   bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_sacs_sable/FeatureServer/",
//   stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Incertitudes_min/FeatureServer/",
//   serviceEssentiel_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Incertitudes_max/FeatureServer/",
//   photoUrls: [
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/inondation2019_mosaic_tif/MapServer",
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/photo2_jpg/MapServer",
//     "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/photo3_jpg/MapServer"
//   ],
//   zi2Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_2/FeatureServer",
//   zi20Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_20/FeatureServer",
//   zi100Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_100/FeatureServer",
//   ziLavalUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ZI_laval_1190m3s/FeatureServer",
//   centerView: [-73.863250, 45.559485],
//   centerViewZoom: 15,
//   btn2019: 16, //1090 m3s
//   btnHier: 5,
//   btnTempsReel: 14,
//   btnUnJour: 20,
//   btnTroisJours: 27,
//   yearMarkers: [
//     {
//       value: 4, // This is the value position in the labels props, equals to 180
//       label: '2', //810
//     },
//     {
//       value: 21,
//       label: '20', //1222
//     },
//     {
//       value: 28, //1408
//       label: '100',
//     }
//   ],
//   isVisible: false
// };
//MilleIles v2
export const MI = {
  configName: 'milleIles',
  scenarioDepart: 3,
  nbScenario: 11,
  startScenario: 1,
  stepScenario: 1,
  labels: ["810", "992", "1095", "1125", "1222", "1335", "1396", "1408", "1542", "1678", "1842"],
  dataKm: [],
  dataSacs: [],
  dataIsole: [40, 36, 38, 70, 64, 66, 68, 56, 78, 82, 62],
  // dataSS: [6, 23, 47, 85, 105, 212, 239, 236, 275, 233, 237],
  // dataPrem: [0, 0, 4, 13, 15, 64, 108, 164, 312, 557, 798],
  dataTot: [6, 23, 51, 98, 120, 276, 347, 400, 587, 790, 1035],
  dataEvac: [142, 178, 265, 482, 524, 998, 1215, 1331, 1970, 2571, 3254],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Batiments_v2/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Batiments_pts_v2/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Batiments_gc_secteur_freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_Routes_v2/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_SacsSable_v2/FeatureServer/",
  stationHydro_Url: "",
  serviceEssentiel_Url: "",
  photoUrls: [
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/inondation2019_mosaic_tif/MapServer",
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/photo2_jpg/MapServer",
    "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/photo3_jpg/MapServer"
  ],
  zi2Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_2/FeatureServer",
  zi20Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_20/FeatureServer",
  zi100Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/MilleIles_ZI_100/FeatureServer",
  // ziLavalUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ZI_laval_1190m3s/FeatureServer",
  centerView: [-73.863250, 45.559485],
  centerViewZoom: 15,
  btn2019: 3,
  // btnHier: 1,
  // btnTempsReel: 2,
  // btnUnJour: 3,
  // btnTroisJours: 4,
  yearMarkers: [
    {
      value: 0,
      label: '2',
    },
    {
      value: 2,
      label: '10',
    },
    {
      value: 4,
      label: '20',
    },
    {
      value: 7,
      label: '100',
    },
    {
      value: 9,
      label: '500',
    }
  ],
  // isVisible: false
};

// Rigaud
export const RI = {
  configName: 'rigaud',
  scenarioDepart: 4,
  nbScenario: 36,
  startScenario: 1,
  stepScenario: 1,
  labels: ["5000", "5200", "5400", "5600", "5800", "6000", "6200", "6400", "6600", "6800", "7000", "7200", "7400", "7600", "7800", "8000", "8200", "8400", "8600", "8800", "9000", "9200", "9400", "9600", "9800", "10000", "10200", "10400", "10600", "10800", "11000", "11200", "11400", "11600", "11800", "12000"],
  dataKm: [],
  dataSacs: [],
  dataIsole: [61, 60, 66, 76, 77, 85, 81, 106, 131, 124, 154, 288, 266, 289, 281, 286, 258, 249, 234, 232, 239, 249, 296, 282, 272, 264, 258, 257, 244, 236, 236, 231, 232, 224, 222, 210],
  // dataSS: [16, 21, 28, 31, 50, 73, 97, 114, 158, 206, 235, 272, 314, 326, 356, 371, 410, 417, 434, 450, 461, 467, 458, 459, 461, 450, 443, 437, 443, 435, 425, 424, 422, 411, 411, 404],
  // dataPrem: [0, 0, 0, 2, 3, 3, 4, 7, 12, 17, 28, 38, 52, 70, 85, 108, 142, 169, 205, 232, 254, 287, 339, 373, 405, 438, 467, 498, 524, 553, 586, 601, 618, 644, 665, 690],
  dataTot: [16, 21, 28, 33, 53, 76, 101, 121, 170, 223, 263, 310, 366, 396, 441, 479, 552, 586, 639, 682, 715, 754, 797, 832, 866, 888, 910, 935, 967, 988, 1011, 1025, 1040, 1055, 1076, 1094],
  dataEvac: [239, 258, 296, 344, 408, 502, 571, 709, 923, 1059, 1261, 1825, 1912, 2077, 2185, 2300, 2428, 2510, 2624, 2742, 2865, 2998, 3277, 3336, 3418, 3464, 3518, 3599, 3654, 3683, 3758, 3780, 3826, 3847, 3911, 3924],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/RigaudV2_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/RigaudV2_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/RigaudV2_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Freestyle/FeatureServer",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/RigaudV2_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/RigaudV2_Sacs_sable/FeatureServer/",
  stationHydro_Url: "",
  serviceEssentiel_Url: "",
  photoUrls: ["https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo1/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo2/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo3/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo4/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo5/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo6/MapServer"],
  zi2Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_ZI_2ans/FeatureServer",
  zi20Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_ZI_20ans/FeatureServer",
  zi100Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_ZI_100ans/FeatureServer",
  centerView: [-74.226, 45.5006],
  centerViewZoom: 12,
  btn2019: 22,
  // btnHier: 5,
  // btnTempsReel: 7,
  // btnUnJour: 9,
  // btnTroisJours: 16,
  yearMarkers: [
    {
      value: 2,
      label: '2', //5395
    },
    {
      value: 12,
      label: '20', //7300
    },
    {
      value: 18,
      label: '100', //8630
    }
  ],
  // isVisible: false
};

// Rigaud Municipalite V3
export const RI_M = {
  configName: 'rigaud_munic',
  scenarioDepart: 4,
  nbScenario: 61,
  startScenario: 1,
  stepScenario: 1,
  labels: ["5000", "5125", "5250", "5375", "5500", "5625", "5750", "5875", "6000", "6125", "6250", "6375", "6500", "6625", "6750", "6875", "7000", "7125", "7250", "7375", "7500", "7625", "7750", "7875", "8000", "8125", "8250", "8375", "8500", "8625", "8750", "8875", "9000", "9125", "9250", "9375", "9500", "9625", "9750", "9875", "10000", "10125", "10250", "10375", "10500", "10625", "10750", "10875", "11000", "11125", "11250", "11375", "11500", "11625", "11750", "11875", "12000", "12125", "12250", "12375", "12500"],
  dataKm: [0.8, 1.1, 1.3, 1.6, 1.7, 2.0, 2.3, 2.4, 2.6, 2.8, 2.9, 3.0, 3.3, 3.8, 4.3, 4.7, 5.3, 6.0, 6.6, 7.2, 7.7, 8.1, 8.4, 8.8, 9.0, 9.3, 9.5, 9.6, 9.9, 10.2, 10.4, 10.6, 10.8, 10.9, 11.1, 11.3, 11.4, 11.5, 11.6, 11.8, 12.0, 12.1, 12.3, 12.4, 12.4, 12.5, 12.6, 12.6, 12.9, 13.0, 13.1, 13.2, 13.2, 13.2, 13.3, 13.3, 13.3, 13.5, 13.2, 13.5, 13.7],
  dataSacs: [538, 660, 1064, 1657, 2494, 2736, 3585, 4714, 7397, 9165, 11097, 13506, 16738, 20180, 24283, 28363, 33810, 41277, 47675, 55061, 62570, 70818, 78788, 86919, 95251, 103955, 112526, 120753, 129281, 138563, 147287, 155958, 165016, 173306, 182851, 191149, 199485, 209430, 217606, 226032, 234276, 244509, 253165, 261997, 270476, 279199, 287623, 296191, 304749, 313275, 322005, 329324, 337995, 346044, 354726, 363691, 371701, 379784, 388643, 396154, 404351],
  dataIsole: [5, 9, 10, 14, 15, 23, 21, 20, 27, 27, 25, 34, 32, 37, 39, 48, 46, 48, 182, 177, 171, 177, 167, 179, 174, 167, 161, 158, 149, 138, 134, 131, 130, 122, 119, 115, 114, 112, 111, 109, 107, 103, 101, 102, 110, 107, 103, 103, 102, 102, 103, 101, 100, 98, 98, 97, 94, 92, 92, 91, 88],
  dataTot: [5, 5, 9, 11, 12, 13, 18, 23, 35, 38, 44, 55, 68, 81, 95, 103, 119, 140, 155, 170, 182, 194, 204, 214, 220, 228, 234, 239, 249, 261, 266, 272, 275, 283, 288, 292, 297, 301, 303, 306, 309, 313, 317, 318, 321, 324, 328, 328, 332, 333, 335, 336, 338, 343, 344, 346, 349, 351, 353, 355, 358],
  dataEvac: [29, 40, 52, 69, 71, 107, 113, 125, 175, 175, 192, 256, 291, 345, 386, 436, 480, 548, 1002, 1034, 1053, 1117, 1117, 1195, 1199, 1202, 1202, 1209, 1212, 1213, 1214, 1223, 1231, 1228, 1235, 1235, 1246, 1253, 1257, 1260, 1265, 1263, 1270, 1279, 1317, 1317, 1317, 1317, 1325, 1329, 1341, 1336, 1341, 1350, 1351, 1356, 1356, 1356, 1364, 1369, 1365],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_Munic_PI_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_Munic_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_Munic_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Freestyle/FeatureServer",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_Munic_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_Munic_SacsSable/FeatureServer/",
  stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_StationHydro/FeatureServer/",
  serviceEssentiel_Url: "",
  photoUrls: ["https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo1/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo2/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo3/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo4/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo5/MapServer",
  "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_Photo6/MapServer"],
  zi2019Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/bouton2019_9500m3s/FeatureServer",
  zi2Url: "",
  zi20Url: "",
  zi100Url: "",
  centerView: [-74.30125, 45.4883],
  centerViewZoom: 14,
  btn2019: 36, // 9500
  // btnHier: 10,
  // btnTempsReel: 20,
  // btnUnJour: 30,
  // btnTroisJours: 40,
  yearMarkers: [
    {
      value: 4,
      label: '2', //5395
    },
    {
      value: 19,
      label: '20', //7300
    },
    {
      value: 30,
      label: '100', //8630
    }
  ],
};

// Champlain Municipalite
export const CH_M = {
  configName: 'champlain_munic',
  scenarioDepart: 4,
  nbScenario: 49,
  startScenario: 1,
  stepScenario: 1,
  labels: ["5000", "5125", "5250", "5375", "5500", "5625", "5750", "5875", "6000", "6125", "6250", "6375", "6500", "6625", "6750", "6875", "7000", "7125", "7250", "7375", "7500", "7625", "7750", "7875", "8000", "8125", "8250", "8375", "8500", "8625", "8750", "8875", "9000", "9125", "9250", "9375", "9500", "9625", "9750", "9875", "10000", "10125", "10250", "10375", "10500", "10625", "10750", "10875", "11000"],
  dataKm: [0.0, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.2, 0.4, 0.4, 0.5, 0.5, 0.6, 0.6, 0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.8, 0.8, 0.8, 0.8, 0.9, 0.9, 1.0, 1.0, 1.0, 1.0, 1.0, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.3, 1.3],
  dataSacs: [0, 44, 64, 89, 231, 298, 387, 464, 549, 782, 961, 1277, 1599, 1940, 2368, 2807, 3272, 3723, 4215, 4763, 5591, 6464, 7512, 8543, 9561, 10611, 11548, 12972, 14289, 15499, 16852, 18292, 19861, 21368, 22967, 24632, 26234, 28584, 30284, 32003, 33698, 35677, 37435, 39218, 41311, 43215, 45057, 47268, 49396],
  dataIsole: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 2, 2, 1, 7, 8, 8, 7, 5, 5, 5, 4, 4, 3, 2, 2, 1, 1, 0, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 9, 8, 9, 8, 8, 7, 7],
  dataTot: [0, 1, 1, 1, 2, 2, 2, 3, 3, 5, 6, 7, 7, 9, 11, 12, 14, 14, 16, 19, 23, 24, 25, 25, 27, 30, 30, 32, 35, 37, 39, 42, 45, 47, 49, 51, 53, 56, 58, 59, 59, 62, 63, 63, 67, 68, 68, 72, 74],
  dataEvac: [0, 2, 2, 2, 7, 7, 7, 11, 11, 18, 32, 32, 32, 36, 37, 61, 73, 73, 75, 77, 92, 96, 96, 96, 99, 107, 107, 109, 119, 123, 152, 164, 149, 154, 160, 162, 170, 178, 184, 189, 189, 195, 202, 198, 211, 211, 211, 220, 226],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Champlain_Munic_PI_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Champlain_Munic_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Champlain_Munic_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Champlain_Munic_freestyle/FeatureServer",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Champlain_Munic_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Champlain_Munic_SacsSable/FeatureServer/",
  stationHydro_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Rigaud_V3_StationHydro/FeatureServer/",
  serviceEssentiel_Url: "",
  photoUrls: [],
  zi2Url: "",
  zi20Url: "",
  zi100Url: "",
  centerView: [-74.665304, 45.624897],
  centerViewZoom: 12,
  btn2019: 22,
  // btnHier: 5,
  // btnTempsReel: 7,
  // btnUnJour: 9,
  // btnTroisJours: 16,
  yearMarkers: [
    {
      value: 2,
      label: '2',
    },
    {
      value: 19,
      label: '20',
    },
    {
      value: 28,
      label: '100',
    }
  ],
};

// Chaudiere
export const CD = {
  configName: 'chaudiere',
  scenarioDepart: 4,
  nbScenario: 10,
  startScenario: 1,
  stepScenario: 1,
  labels: ["1474", "1762", "1978", "2196", "2484", "2611", "2701", "2918", "3206", "3550"],
  dataKm: [],
  dataSacs: [],
  dataIsole: [1, 1, 10, 202, 334, 288, 327, 689, 746, 994],
  dataTot: [23, 38, 101, 294, 684, 846, 978, 1223, 1539, 1842],
  dataEvac: [73, 131, 339, 1440, 3039, 3373, 3882, 5690, 6828, 8467],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Chaudiere_Centre_PI_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereCentre_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereCentre_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereCentre_Freestyle/FeatureServer",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereCentre_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ChaudiereCentre_SacsSable/FeatureServer/",
  stationHydro_Url: "",
  serviceEssentiel_Url: "",
  photoUrls: [],
  zi2Url: "",
  zi20Url: "",
  zi100Url: "",
  centerView: [-71.023084, 46.438225],
  centerViewZoom: 15,
  btn2019: 4,
  // btnHier: 1,
  // btnTempsReel: 2,
  // btnUnJour: 3,
  // btnTroisJours: 5,
  yearMarkers: [
    {
      value: 0,
      label: '2',
    },
    {
      value: 3,
      label: '20',
    },
    {
      value: 6,
      label: '100',
    }
  ],
  // isVisible: false
};