import React, {useEffect, useRef, useState} from 'react'
import "./Histogram.css";
import Chart from "chart.js";
import 'chartjs-plugin-annotation';
import {connect} from "react-redux";
// let waterLevel;
// export default class LineGraph extends Component {
// class LineGraph extends Component {
//     chartRef = React.createRef();
//
//     componentDidMount() {
//         // document.addEventListener("waterLevel", function (event) {
//         //     waterLevel = event.detail;
//         // });
//         // if (typeof waterLevel == 'undefined') {
//         //     waterLevel = this.props.scenarioDepart;
//         // }
//
//         console.log(this.props.currentScenario)
//         const myChartRef = this.chartRef.current.getContext("2d");
//
//         new Chart(myChartRef, {
//             type: "line",
//             data: {
//                 labels: this.props.labels,
//                 datasets: [
//                     {
//                         data: this.props.data,
//                         borderColor: "#cd1820",
//                         backgroundColor: "#ff3946",
//                         fill: true,
//                         // cubicInterpolationMode: true
//                     }
//                 ]
//             },
//             options: {
//                 legend: {
//                     display: false,
//                 },
//                 title: {
//                     display: true,
//                     text: this.props.title,
//                     fontColor: 'gray',
//                     fontSize: 12
//                 },
//                 elements: {
//                     point: {
//                         pointStyle: 'circle',
//                         radius: 2
//                     },
//                 },
//                 // scales: {
//                 //     yAxes: [{
//                 //         ticks: {
//                 //             min: 0,
//                 //             max: 100,
//                 //             stepSize: 20,
//                 //         }
//                 //     }]
//                 // },
//                 annotation: {
//                     annotations: [
//                         {
//                             type: "line",
//                             mode: "vertical",
//                             scaleID: "x-axis-0",
//                             value: this.props.labels[this.props.currentScenario],
//                             borderColor: "blue",
//                             borderWidth: 2,
//                             // borderDash: [2, 2],
//
//                         }
//                     ]
//                 }
//             }
//
//         })
//         ;
//     }
//     render() {
//         return (
//
//             <div className="graph">
//                 <canvas id="myChart" ref={this.chartRef}/>
//                 {/*<span id="closeChart"/>*/}
//             </div>
//         )
//     }
// }

const LineGraph = (props) => {
    const chartConfig = {
        type: "line",
        data: {
            labels: props.labels,
            datasets: [
                {
                    data: props.data,
                    label: props.dataLabel,
                    borderColor: "#cd1820",
                    backgroundColor: "#ff3946",
                    fill: true,
                    // cubicInterpolationMode: true
                }
            ]
        },
        options: {
            legend: {
                display: false,
            },
            scales: {
                // xAxes: [{
                //     display: true,
                //     scaleLabel: {
                //         display: true,
                //         labelString: 'Month'
                //     }
                // }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: props.dataLabel
                    }
                }]
            },
            title: {
                display: true,
                text: props.title,
                fontColor: 'gray',
                fontSize: 12
            },
            elements: {
                point: {
                    pointStyle: 'circle',
                    radius: 2
                },
            },
            annotation: {
                annotations: [
                    {
                        type: "line",
                        mode: "vertical",
                        scaleID: "x-axis-0",
                        value: props.labels[props.currentScenario],
                        borderColor: "blue",
                        borderWidth: 2,
                    }
                ]
            }
        }
    };
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer, props.currentScenario, LineGraph]);
    // }, [chartContainer, props.currentScenario]);

    return (
        <div className="graph">
            <canvas id="myChart" ref={chartContainer}/>
            {/*<span id="closeChart"/>*/}
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        currentScenario: state.scenario
    };
};

export default connect(mapStateToProps)(LineGraph);
