import React, { useState } from "react";
import "./Mentions.css";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const Mentions = () => {
    const [mentionsState, mentionsSetState] = useState({
        toggleMentions: false
    });
    const mentionsToggle = () => {
        mentionsSetState({
            toggleMentions: !mentionsState.toggleMentions
        })
    };
    return (
        <>
            <button id="btn-toggle-mentions" onClick={mentionsToggle}>
                <FontAwesomeIcon icon={faInfoCircle} size="2x" color={mentionsState.toggleMentions ? "var(--color-cyan)" : "var(--color-bg-stats)"}/>
            </button>

            <div id="div-mentions" style={{display: mentionsState.toggleMentions ? 'block' : 'none' }}>
                <p>Les jeux de données « LIDAR – Modèles numériques (terrain, canopée, pente) » et « Adresses Québec » utilisés par Géosapiens sont mis à disposition par <a href="https://www.donneesquebec.ca/fr/" rel="noopener noreferrer" target="_blank">Données Québec</a> sous licence <a href="https://creativecommons.org/licenses/by/4.0/legalcode.fr" rel="noopener noreferrer" target="_blank">CC Attribution (BY 4.0)</a> et leur version originale est disponible <a href="https://www.donneesquebec.ca/fr/" rel="noopener noreferrer" target="_blank">ici</a>. Géosapiens peut superposer des informations sur les données issues du jeu de données « Adresses Québec » pour permettre le rendu de ses services.</p>
                <p>La base de données « Canadian Building Footprints » utilisée par Géosapiens est mise à disposition par Microsoft sous licence <a href="https://opendatacommons.org/licenses/odbl/1.0/" rel="noopener noreferrer" target="_blank">Open Data Commons Open Database License (ODbL) V.1.0</a> et une version originale est disponible <a href="https://github.com/Microsoft/CanadianBuildingFootprints" rel="noopener noreferrer" target="_blank">ici</a>.</p>
                <p>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
            </div>
            <div id="copyright">
              <p>© 2021 Geosapiens</p>
            </div>
        </>
    );
};